import React from 'react';
import Breadcrumbs, { Crumb } from '../../../components/tailwind/Breadcrumbs';
import { ShopPageHeader } from '@types';

type Props = {
  header: ShopPageHeader | undefined | null;
  crumbs?: Crumb[] | undefined | null;
};

const PageHeader = ({ header, crumbs }: Props) => {
  return (
    <>
      {crumbs && <Breadcrumbs crumbs={crumbs} />}
      {header && (
        <div className='tw-mb-7 tw-px-5 tw-py-3'>
          <h1 className='tw-mb-2 tw-text-center tw-font-title tw-text-3xl tw-font-bold tw-text-brand-black lg:tw-text-[64px] lg:tw-leading-tight'>
            {header.title}
          </h1>
          <p className='tw-mx-auto tw-mb-0 tw-max-w-3xl tw-text-center tw-text-sm tw-text-gray-dark lg:tw-text-base'>
            {header.subtitle}
          </p>
        </div>
      )}
    </>
  );
};

export default PageHeader;
