import axios from 'axios';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

const usePageDesign = (defaultPageDesign) => {
  const location = useLocation();
  const [pageDesign, setPageDesign] = useState(defaultPageDesign);
  const [path, setPath] = useState(location.pathname);
  const [pathChanged, setPathChanged] = useState(false);
  const { isLoading, isFetching } = useQuery<any>({
    queryKey: ['pageDesign', path],
    queryFn: async () => {
      const { data } = await axios.get(path);
      return data;
    },
    enabled: !!path && pathChanged,
    keepPreviousData: false,
    onSuccess: (data) => {
      setPageDesign(data);

      if (data?.pageDesign?.renderMethod !== 'react') {
        // @ts-ignore
        window.location = location.pathname;
      }
    },
    onError: (error) => {
      // @ts-ignore
      window.location = location.pathname;
    },
  });

  useEffect(() => {
    if (path !== location.pathname) {
      setPath(location.pathname);
      setPathChanged(true);
    }
  }, [location.pathname, path]);

  return { pageDesign, isLoading: isLoading || isFetching };
};

export default usePageDesign;
