import { ShopRoutes } from '@types';

export const REACT_URLS: ShopRoutes[] = [
  ShopRoutes.HARD_COOLERS,
  ShopRoutes.MY_RTIC,
  ShopRoutes.NASCAR,
  ShopRoutes.ULTRA_LIGHT_COOLERS,
  ShopRoutes.AUTHORIZED_RETAILERS,
];

export const pageContent: { [key in ShopRoutes] } = {
  [ShopRoutes.HARD_COOLERS]: {
    crumbs: [
      {
        text: 'Home',
        url: '/',
        isInternalLink: false,
      },
      {
        text: 'Hard Coolers',
        url: '#',
        isInternalLink: false,
      },
    ],
    header: {
      title: 'Hard Coolers',
      subtitle: 'We’ve designed two distinct collections so you can pick the right cooler with confidence.',
    },
    productCategories: [
      {
        id: 'UltraLightCoolers',
        headerBg: {
          desktop: require('./images/hard-coolers/ul-card-hero_d.jpg'),
          mobile: require('./images/hard-coolers/ul-card-hero_m.jpg'),
        },
        sectionBg: {
          desktop: require('./images/hard-coolers/ultra-light-section_d.jpg'),
          mobile: require('./images/hard-coolers/ultra-light-section_m.jpg'),
        },
        title: 'Ultra-Light Coolers',
        itemType: 'Ultra-Light Cooler',
        subtitle:
          'Our Ultra-Light Hard Coolers are more than 30% lighter than Roto-Molded coolers of the same capacity.',
        availableSizes: [
          // This will be filtered based on existing sizes sent from server
          {
            typeId: 355,
            imageUrl: require('./images/hard-coolers/ultra-light-sizes/32qt.png'),
            label: `<span><strong>32</strong><sup>QT</sup></span>`,
            features: [
              'Weekend Adventures',
              'Holds 48 Cans | 30 lbs Ice',
              'Weighs 13.4 lbs',
              '23 x 15&frac12; x 14&frac12;',
            ],
          },
          {
            typeId: 70,
            imageUrl: require('./images/hard-coolers/ultra-light-sizes/52qt.png'),
            label: `<span><strong>52</strong><sup>QT</sup></span>`,
            features: [
              'Weeklong Ventures',
              'Holds 70 Cans | 60 lbs Ice',
              'Weighs 21 lbs',
              `<span>27 x 18 x 18</span>`,
            ],
          },
          {
            typeId: 388,
            imageUrl: require('./images/hard-coolers/ultra-light-sizes/52qt-wheeled.png'),
            label: `<span><strong>Wheeled 52</strong><sup>QT</sup></span>`,
            features: [
              'Weeklong Ventures',
              'Holds 78 Cans | 53 lbs Ice',
              'Weighs 30 lbs',
              `<span>18 ¾” x 29” x 18 ¼”</span>`,
            ],
          },
        ],
        features: [
          {
            icon: require('./images/hard-coolers/weight.svg'),
            title: 'Up to 30% Lighter',
            subtitle: 'Engineered to be up to 30% lighter than competing premium coolers.',
          },
          {
            icon: require('./images/hard-coolers/tailor-ruler.svg'),
            title: 'Tailor Made For',
            subtitle: 'Backyard BBQs, Parties, Beachside, Poolside, Weekend Camping',
          },
        ],
        url: '/shop/coolers/hard-sided/ultra-light',
      },
      {
        id: 'OriginalHardCoolers',
        headerBg: {
          desktop: require('./images/hard-coolers/hard-cooler-card-hero_d.jpg'),
          mobile: require('./images/hard-coolers/hard-cooler-card-hero_m.jpg'),
        },
        sectionBg: {
          desktop: require('./images/hard-coolers/hard-cooler-section_d.jpg'),
          mobile: require('./images/hard-coolers/hard-cooler-section_m.jpg'),
        },
        title: 'Original Hard Coolers',
        itemType: 'Original Hard Cooler',
        subtitle:
          'Our Original Hard Coolers are all about extremes - from impact resistance and durability to long lasting ice retention.',
        availableSizes: [
          // This will be filtered based on existing sizes sent from server
          {
            typeId: 121,
            imageUrl: require('./images/hard-coolers/hard-cooler-sizes/20qt.png'),
            label: `<span><strong>20</strong><sup>QT</sup></span>`,
            features: [],
          },
          {
            typeId: 166,
            imageUrl: require('./images/hard-coolers/hard-cooler-sizes/45qt.png'),
            label: `<span><strong>45</strong><sup>QT</sup></span>`,
            features: [],
          },
          {
            typeId: 167,
            imageUrl: require('./images/hard-coolers/hard-cooler-sizes/65qt.png'),
            label: `<span><strong>65</strong><sup>QT</sup></span>`,
            features: [],
          },
          {
            typeId: 168,
            imageUrl: require('./images/hard-coolers/hard-cooler-sizes/110qt.png'),
            label: `<span><strong>110</strong><sup>QT</sup></span>`,
            features: [],
          },
          {
            typeId: 169,
            imageUrl: require('./images/hard-coolers/hard-cooler-sizes/145qt.png'),
            label: `<span><strong>145</strong><sup>QT</sup></span>`,
            features: [],
          },
        ],
        features: [
          {
            icon: require('./images/hard-coolers/muscles.svg'),
            title: 'Practically Indestructible',
            subtitle: 'One-Piece, Roto-Molded Construction For Maximum Durability',
          },
          {
            icon: require('./images/hard-coolers/tailor-ruler.svg'),
            title: 'Tailor Made For',
            subtitle: 'Fishing, Hunting, Long Excursions, Rafting, Truck Beds & Boats',
          },
        ],
        url: '/shop/coolers/hard-sided/original',
      },
    ],
  },
  [ShopRoutes.MY_RTIC]: {},
  [ShopRoutes.NASCAR]: {
    crumbs: [
      {
        text: 'Home',
        url: '/',
        isInternalLink: false,
      },
      {
        text: 'MyRTIC',
        url: '/myrtic',
        isInternalLink: true,
      },
      {
        text: 'Nascar',
        url: '/nascar',
        isInternalLink: false,
      },
    ],
  },
  [ShopRoutes.ULTRA_LIGHT_COOLERS]: {
    header: {
      title: 'Ultra-Light Collection',
      image: {
        desktop: require('./images/ultra-light/banner-placeholder_d.jpg'),
        mobile: require('./images/ultra-light/banner-placeholder_m.jpg'),
        alt: 'Ultra-Light Collection Banner',
      },
      video: {
        desktop: 'https://static.velkybrands.com/v/ul_collection_d.mp4',
        mobile: 'https://static.velkybrands.com/v/ul_collection_m.mp4',
      },
    },
    sliderImages: [
      {
        navTitle: '72QT Ultra-Light Wheeled Cooler',
        title: 'Meet The 72QT Ultra-Light Wheeled Cooler',
        description:
          'The 72QT Ultra-Light Wheeled Cooler lets you spend more time enjoying the moment, and less time refilling. Designed to fit 2 RTIC Baskets & Dividers, with puncture-proof, all-terrain wheels, and a 96 can capacity.',
        image: {
          desktop: require('./images/ultra-light/meet the 72 wheeled_d.jpg'),
          mobile: require('./images/ultra-light/meet the 72 wheeled_m.jpg'),
          alt: '72QT Ultra-Light Wheeled Cooler Image',
        },
      },
      {
        navTitle: '52QT Ultra-Light Wheeled Cooler',
        title: 'Meet The 52QT Ultra-Light Wheeled Cooler',
        description:
          'Our very first Wheeled cooler that’s strong enough to hold over 2 tons and light enough to take everywhere–while still offering up to 5 days of premium ice retention.',
        image: {
          desktop: require('./images/ultra-light/wheeled-52qt_d.jpg'),
          mobile: require('./images/ultra-light/wheeled-52qt_m.jpg'),
          alt: '52QT Ultra-Light Wheeled Cooler Image',
        },
      },
      {
        navTitle: '32QT Ultra-Light Cooler',
        title: 'Meet the 32Qt Ultra-Light Cooler',
        description:
          'The 32QT Ultra-Light is an even more portable version of our best-selling cooler, with 2.5 inches of insulation for multiple days of ice retention.',
        image: {
          desktop: require('./images/ultra-light/32qt_d.jpg'),
          mobile: require('./images/ultra-light/32qt_m.jpg'),
          alt: '32QT Ultra-Light Cooler Image',
        },
      },
      {
        navTitle: '52QT Ultra-Light Cooler',
        title: 'Meet the 52Qt Ultra-Light Cooler',
        description:
          'The original 52QT Ultra-Light is a strong, yet lightweight, premium hard-sided cooler featuring 3 inches of insulation for multiple days of ice retention.',
        image: {
          desktop: require('./images/ultra-light/52qt_d.jpg'),
          mobile: require('./images/ultra-light/52qt_m.jpg'),
          alt: '52QT Ultra-Light Cooler Image',
        },
      },
      {
        navTitle: 'Halftime Water Cooler',
        title: 'Halftime Water Cooler',
        description:
          'The Halftime Water Cooler has dual, fast-fill taps so you can fill up twice the drinks in half the time, whether it’s at a sports game, local restaurant, job site, or party!',
        image: {
          desktop: require('./images/ultra-light/meet-the-halftime-wc-image_d.jpg'),
          mobile: require('./images/ultra-light/meet-the-halftime-wc-image_m.jpg'),
          alt: 'Halftime Water Cooler Image',
        },
      },
    ],
  },
  [ShopRoutes.AUTHORIZED_RETAILERS]: {},
  [ShopRoutes.WARRANTY]: {},
};
